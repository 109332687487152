export default {
  namespaced: true,

  state: {
    layouts: {
      MUSICA: {
        CardProductHorizontal: {
          header: {
            type: 'attribute',
            value: 'AUTORE',
          },
          body: {
            type: 'field',
            value: 'description',
          },
          footerDetails: [
            {
              type: 'field',
              value: 'productCategoryName',
            },
            {
              type: 'field',
              value: 'productSubcategoryName',
            },
            {
              type: 'field',
              value: 'sku',
            },
            {
              type: 'field',
              value: 'availableFrom',
            },
            {
              type: 'field',
              value: 'productBrandName',
            },
            {
              type: 'attribute',
              value: 'ETICHETTA',
            },
            {
              type: 'attribute',
              value: 'CATALOGO',
            },
          ],
        },
        CardProductVertical: {
          header: {
            type: 'attribute',
            value: 'AUTORE',
          },
          body: {
            type: 'field',
            value: 'description',
          },
          footerDetails: [
            {
              type: 'field',
              value: 'productCategoryName',
            },
            {
              type: 'field',
              value: 'productSubcategoryName',
            },
            {
              type: 'field',
              value: 'sku',
            },
            {
              type: 'field',
              value: 'availableFrom',
            },
            {
              type: 'field',
              value: 'productBrandName',
            },
            {
              type: 'attribute',
              value: 'ETICHETTA',
            },
            {
              type: 'attribute',
              value: 'CATALOGO',
            },
          ],
        },
        CardProductHome: {
          header: {
            type: 'attribute',
            value: 'AUTORE',
          },
          body: {
            type: 'field',
            value: 'description',
          },
          footerDetails: [
            {
              type: 'field',
              value: 'availableFrom',
            },
          ],
        },
        CartProduct: {
          header: {
            type: 'attribute',
            value: 'AUTORE',
          },
          body: {
            type: 'field',
            value: 'description',
          },
          footerDetails: [
            {
              type: 'field',
              value: 'productCategoryName',
            },
            {
              type: 'field',
              value: 'productSubcategoryName',
            },
            {
              type: 'field',
              value: 'sku',
            },
            {
              type: 'field',
              value: 'availableFrom',
            },
            {
              type: 'field',
              value: 'productBrandName',
            },
            {
              type: 'attribute',
              value: 'ETICHETTA',
            },
            {
              type: 'attribute',
              value: 'CATALOGO',
            },
          ],
        },
        ProductInfo: {
          header: {
            type: 'attribute',
            value: 'AUTORE',
          },
          body: {
            type: 'field',
            value: 'description',
          },
          details: [
            {
              type: 'field',
              value: 'productCategoryName',
            },
            {
              type: 'field',
              value: 'productSubcategoryName',
            },
            {
              type: 'field',
              value: 'sku',
            },
            {
              type: 'field',
              value: 'availableFrom',
            },
            {
              type: 'field',
              value: 'productBrandName',
            },
            {
              type: 'attribute',
              value: 'CATALOGO',
            },
            {
              type: 'attribute',
              value: 'ETICHETTA',
            },
            {
              type: 'attribute',
              value: 'GENERE',
            },
            {
              type: 'attribute',
              value: 'COMPOSITORE',
            },
          ],
          tabs: [
            {
              label: 'Note',
              type: 'attribute',
              component: 'TextCard',
              value: 'NOTE_PRODOTTO',
              lang: 'it',
              iconUrl: '',
            },
            {
              label: 'Notes',
              type: 'attribute',
              component: 'TextCard',
              value: 'NOTE_PRODOTTO_EN',
              lang: 'en',
              iconUrl: '',
            },
            {
              label: 'Tracklist',
              type: 'attribute',
              component: 'ListCard',
              value: 'TRACKLIST',
              iconUrl: '',
            },
            {
              label: 'Media',
              type: 'attribute',
              component: 'IframeCard',
              value: ['YOUTUBE', 'SPOTIFY'],
              iconUrl: '',
            },
          ],
        },
      },
      HOME_VIDEO: {
        CardProductHorizontal: {
          header: {
            type: 'attribute',
            value: 'AUTORE',
          },
          body: {
            type: 'field',
            value: 'description',
          },
          footerDetails: [
            {
              type: 'field',
              value: 'productCategoryName',
            },
            {
              type: 'field',
              value: 'productSubcategoryName',
            },
            {
              type: 'field',
              value: 'sku',
            },
            {
              type: 'field',
              value: 'availableFrom',
            },
            {
              type: 'field',
              value: 'productBrandName',
            },
            {
              type: 'attribute',
              value: 'ETICHETTA',
            },
            {
              type: 'attribute',
              value: 'CATALOGO',
            },
          ],
        },
        CardProductVertical: {
          header: {
            type: 'attribute',
            value: 'AUTORE',
          },
          body: {
            type: 'field',
            value: 'description',
          },
          footerDetails: [
            {
              type: 'field',
              value: 'productCategoryName',
            },
            {
              type: 'field',
              value: 'productSubcategoryName',
            },
            {
              type: 'field',
              value: 'sku',
            },
            {
              type: 'field',
              value: 'availableFrom',
            },
            {
              type: 'field',
              value: 'productBrandName',
            },
            {
              type: 'attribute',
              value: 'ETICHETTA',
            },
            {
              type: 'attribute',
              value: 'CATALOGO',
            },
          ],
        },
        CardProductHome: {
          header: {
            type: 'attribute',
            value: 'AUTORE',
          },
          body: {
            type: 'field',
            value: 'description',
          },
          footerDetails: [
            {
              type: 'field',
              value: 'availableFrom',
            },
          ],
        },
        CartProduct: {
          header: {
            type: 'attribute',
            value: 'AUTORE',
          },
          body: {
            type: 'field',
            value: 'description',
          },
          footerDetails: [
            {
              type: 'field',
              value: 'productCategoryName',
            },
            {
              type: 'field',
              value: 'productSubcategoryName',
            },
            {
              type: 'field',
              value: 'sku',
            },
            {
              type: 'field',
              value: 'availableFrom',
            },
            {
              type: 'field',
              value: 'productBrandName',
            },
            {
              type: 'attribute',
              value: 'ETICHETTA',
            },
            {
              type: 'attribute',
              value: 'CATALOGO',
            },
          ],
        },
        ProductInfo: {
          header: {
            type: 'attribute',
            value: 'AUTORE',
          },
          body: {
            type: 'field',
            value: 'description',
          },
          details: [
            {
              type: 'field',
              value: 'productCategoryName',
            },
            {
              type: 'field',
              value: 'productSubcategoryName',
            },
            {
              type: 'field',
              value: 'sku',
            },
            {
              type: 'field',
              value: 'availableFrom',
            },
            {
              type: 'field',
              value: 'productBrandName',
            },
            {
              type: 'attribute',
              value: 'CATALOGO',
            },
            {
              type: 'attribute',
              value: 'ETICHETTA',
            },
            {
              type: 'attribute',
              value: 'GENERE',
            },
            {
              type: 'attribute',
              value: 'COMPOSITORE',
            },
          ],
          tabs: [
            {
              label: 'Note',
              type: 'attribute',
              component: 'TextCard',
              value: 'NOTE_PRODOTTO',
              lang: 'it',
              iconUrl: '',
            },
            {
              label: 'Notes',
              type: 'attribute',
              component: 'TextCard',
              value: 'NOTE_PRODOTTO_EN',
              lang: 'en',
              iconUrl: '',
            },
            {
              label: 'Media',
              type: 'attribute',
              component: 'IframeCard',
              value: ['YOUTUBE'],
              iconUrl: '',
            },
          ],
        },
      },
      HI_FI: {
        CardProductHorizontal: {
          header: {
            type: 'field',
            value: 'productBrandName',
          },
          body: {
            type: 'field',
            value: 'description',
          },
          footerDetails: [
            {
              type: 'field',
              value: 'productCategoryName',
            },
            {
              type: 'field',
              value: 'productSubcategoryName',
            },
            {
              type: 'field',
              value: 'sku',
            },
            {
              type: 'field',
              value: 'availableFrom',
            },
            {
              type: 'attribute',
              value: 'ETICHETTA',
            },
            {
              type: 'attribute',
              value: 'CATALOGO',
            },
          ],
        },
        CardProductVertical: {
          header: {
            type: 'field',
            value: 'productBrandName',
          },
          body: {
            type: 'field',
            value: 'description',
          },
          footerDetails: [
            {
              type: 'field',
              value: 'productCategoryName',
            },
            {
              type: 'field',
              value: 'productSubcategoryName',
            },
            {
              type: 'field',
              value: 'sku',
            },
            {
              type: 'field',
              value: 'availableFrom',
            },
            {
              type: 'attribute',
              value: 'ETICHETTA',
            },
            {
              type: 'attribute',
              value: 'CATALOGO',
            },
          ],
        },
        CartProduct: {
          header: {
            type: 'field',
            value: 'productBrandName',
          },
          body: {
            type: 'field',
            value: 'description',
          },
          footerDetails: [
            {
              type: 'field',
              value: 'productCategoryName',
            },
            {
              type: 'field',
              value: 'productSubcategoryName',
            },
            {
              type: 'field',
              value: 'sku',
            },
            {
              type: 'field',
              value: 'availableFrom',
            },
            {
              type: 'attribute',
              value: 'ETICHETTA',
            },
            {
              type: 'attribute',
              value: 'CATALOGO',
            },
          ],
        },
        ProductInfo: {
          header: {
            type: 'field',
            value: 'productBrandName',
          },
          body: {
            type: 'field',
            value: 'description',
          },
          details: [
            {
              type: 'field',
              value: 'productCategoryName',
            },
            {
              type: 'field',
              value: 'productSubcategoryName',
            },
            {
              type: 'field',
              value: 'sku',
            },
            {
              type: 'field',
              value: 'availableFrom',
            },
            {
              type: 'attribute',
              value: 'CATALOGO',
            },
            {
              type: 'attribute',
              value: 'ETICHETTA',
            },
            {
              type: 'attribute',
              value: 'GENERE',
            },
            {
              type: 'attribute',
              value: 'COMPOSITORE',
            },
          ],
          tabs: [
            {
              label: 'Note',
              type: 'attribute',
              component: 'TextCard',
              value: 'NOTE_PRODOTTO',
              lang: 'it',
              iconUrl: '',
            },
            {
              label: 'Notes',
              type: 'attribute',
              component: 'TextCard',
              value: 'NOTE_PRODOTTO_EN',
              lang: 'en',
              iconUrl: '',
            },
          ],
        },
      },
      MERCHANDISE: {
        CardProductHorizontal: {
          header: {
            type: 'field',
            value: 'productBrandName',
          },
          body: {
            type: 'field',
            value: 'description',
          },
          footerDetails: [
            {
              type: 'field',
              value: 'productCategoryName',
            },
            {
              type: 'field',
              value: 'productSubcategoryName',
            },
            {
              type: 'field',
              value: 'sku',
            },
            {
              type: 'field',
              value: 'availableFrom',
            },
            {
              type: 'attribute',
              value: 'ETICHETTA',
            },
            {
              type: 'attribute',
              value: 'CATALOGO',
            },
          ],
        },
        CardProductVertical: {
          header: {
            type: 'field',
            value: 'productBrandName',
          },
          body: {
            type: 'field',
            value: 'description',
          },
          footerDetails: [
            {
              type: 'field',
              value: 'productCategoryName',
            },
            {
              type: 'field',
              value: 'productSubcategoryName',
            },
            {
              type: 'field',
              value: 'sku',
            },
            {
              type: 'field',
              value: 'availableFrom',
            },
            {
              type: 'attribute',
              value: 'ETICHETTA',
            },
            {
              type: 'attribute',
              value: 'CATALOGO',
            },
          ],
        },
        CartProduct: {
          header: {
            type: 'field',
            value: 'productBrandName',
          },
          body: {
            type: 'field',
            value: 'description',
          },
          footerDetails: [
            {
              type: 'field',
              value: 'productCategoryName',
            },
            {
              type: 'field',
              value: 'productSubcategoryName',
            },
            {
              type: 'field',
              value: 'sku',
            },
            {
              type: 'field',
              value: 'availableFrom',
            },
            {
              type: 'attribute',
              value: 'ETICHETTA',
            },
            {
              type: 'attribute',
              value: 'CATALOGO',
            },
          ],
        },
        ProductInfo: {
          header: {
            type: 'field',
            value: 'productBrandName',
          },
          body: {
            type: 'field',
            value: 'description',
          },
          details: [
            {
              type: 'field',
              value: 'productCategoryName',
            },
            {
              type: 'field',
              value: 'productSubcategoryName',
            },
            {
              type: 'field',
              value: 'sku',
            },
            {
              type: 'field',
              value: 'availableFrom',
            },
            {
              type: 'attribute',
              value: 'CATALOGO',
            },
            {
              type: 'attribute',
              value: 'ETICHETTA',
            },
            {
              type: 'attribute',
              value: 'GENERE',
            },
            {
              type: 'attribute',
              value: 'COMPOSITORE',
            },
          ],
          tabs: [
            {
              label: 'Note',
              type: 'attribute',
              component: 'TextCard',
              value: 'NOTE_PRODOTTO',
              lang: 'it',
              iconUrl: '',
            },
            {
              label: 'Notes',
              type: 'attribute',
              component: 'TextCard',
              value: 'NOTE_PRODOTTO_EN',
              lang: 'en',
              iconUrl: '',
            },
          ],
        },
      },
      EDITORIA: {
        CardProductHorizontal: {
          header: {
            type: 'field',
            value: 'productBrandName',
          },
          body: {
            type: 'field',
            value: 'description',
          },
          footerDetails: [
            {
              type: 'field',
              value: 'productCategoryName',
            },
            {
              type: 'field',
              value: 'productSubcategoryName',
            },
            {
              type: 'field',
              value: 'sku',
            },
            {
              type: 'field',
              value: 'availableFrom',
            },
            {
              type: 'attribute',
              value: 'ETICHETTA',
            },
            {
              type: 'attribute',
              value: 'CATALOGO',
            },
          ],
        },
        CardProductVertical: {
          header: {
            type: 'field',
            value: 'productBrandName',
          },
          body: {
            type: 'field',
            value: 'description',
          },
          footerDetails: [
            {
              type: 'field',
              value: 'productCategoryName',
            },
            {
              type: 'field',
              value: 'productSubcategoryName',
            },
            {
              type: 'field',
              value: 'sku',
            },
            {
              type: 'field',
              value: 'availableFrom',
            },
            {
              type: 'attribute',
              value: 'ETICHETTA',
            },
            {
              type: 'attribute',
              value: 'CATALOGO',
            },
          ],
        },
        CartProduct: {
          header: {
            type: 'field',
            value: 'productBrandName',
          },
          body: {
            type: 'field',
            value: 'description',
          },
          footerDetails: [
            {
              type: 'field',
              value: 'productCategoryName',
            },
            {
              type: 'field',
              value: 'productSubcategoryName',
            },
            {
              type: 'field',
              value: 'availableFrom',
            },
            {
              type: 'field',
              value: 'sku',
            },
            {
              type: 'attribute',
              value: 'ETICHETTA',
            },
            {
              type: 'attribute',
              value: 'CATALOGO',
            },
          ],
        },
        ProductInfo: {
          header: {
            type: 'field',
            value: 'productBrandName',
          },
          body: {
            type: 'field',
            value: 'description',
          },
          details: [
            {
              type: 'field',
              value: 'productCategoryName',
            },
            {
              type: 'field',
              value: 'productSubcategoryName',
            },
            {
              type: 'field',
              value: 'sku',
            },
            {
              type: 'field',
              value: 'availableFrom',
            },
            {
              type: 'attribute',
              value: 'CATALOGO',
            },
            {
              type: 'attribute',
              value: 'ETICHETTA',
            },
            {
              type: 'attribute',
              value: 'GENERE',
            },
            {
              type: 'attribute',
              value: 'COMPOSITORE',
            },
          ],
          tabs: [
            {
              label: 'Note',
              type: 'attribute',
              component: 'TextCard',
              value: 'NOTE_PRODOTTO',
              lang: 'it',
              iconUrl: '',
            },
            {
              label: 'Notes',
              type: 'attribute',
              component: 'TextCard',
              value: 'NOTE_PRODOTTO_EN',
              lang: 'en',
              iconUrl: '',
            },
          ],
        },
      },
      default: {
        CardProductHorizontal: {
          header: {
            type: 'attribute',
            value: 'AUTORE',
          },
          body: {
            type: 'field',
            value: 'description',
          },
          footerDetails: [
            {
              type: 'field',
              value: 'productCategoryName',
            },
            {
              type: 'field',
              value: 'productSubcategoryName',
            },
            {
              type: 'field',
              value: 'sku',
            },
            {
              type: 'field',
              value: 'availableFrom',
            },
            {
              type: 'field',
              value: 'productBrandName',
            },
            {
              type: 'attribute',
              value: 'ETICHETTA',
            },
            {
              type: 'attribute',
              value: 'CATALOGO',
            },
          ],
        },
        CardProductVertical: {
          header: {
            type: 'attribute',
            value: 'AUTORE',
          },
          body: {
            type: 'field',
            value: 'description',
          },
          footerDetails: [
            {
              type: 'field',
              value: 'productCategoryName',
            },
            {
              type: 'field',
              value: 'productSubcategoryName',
            },
            {
              type: 'field',
              value: 'sku',
            },
            {
              type: 'field',
              value: 'availableFrom',
            },
            {
              type: 'field',
              value: 'productBrandName',
            },
            {
              type: 'attribute',
              value: 'ETICHETTA',
            },
            {
              type: 'attribute',
              value: 'CATALOGO',
            },
          ],
        },
        CartProduct: {
          header: {
            type: 'attribute',
            value: 'AUTORE',
          },
          body: {
            type: 'field',
            value: 'description',
          },
          footerDetails: [
            {
              type: 'field',
              value: 'productCategoryName',
            },
            {
              type: 'field',
              value: 'productSubcategoryName',
            },
            {
              type: 'field',
              value: 'sku',
            },
            {
              type: 'field',
              value: 'availableFrom',
            },
            {
              type: 'field',
              value: 'productBrandName',
            },
            {
              type: 'attribute',
              value: 'ETICHETTA',
            },
            {
              type: 'attribute',
              value: 'CATALOGO',
            },
          ],
        },
        ProductInfo: {
          header: {
            type: 'attribute',
            value: 'AUTORE',
          },
          body: {
            type: 'field',
            value: 'description',
          },
          details: [
            {
              type: 'field',
              value: 'productCategoryName',
            },
            {
              type: 'field',
              value: 'productSubcategoryName',
            },
            {
              type: 'field',
              value: 'sku',
            },
            {
              type: 'field',
              value: 'availableFrom',
            },
            {
              type: 'field',
              value: 'productBrandName',
            },
            {
              type: 'attribute',
              value: 'CATALOGO',
            },
            {
              type: 'attribute',
              value: 'ETICHETTA',
            },
            {
              type: 'attribute',
              value: 'GENERE',
            },
            {
              type: 'attribute',
              value: 'COMPOSITORE',
            },
          ],
          tabs: [
            {
              label: 'Note',
              type: 'attribute',
              component: 'TextCard',
              value: 'NOTE_PRODOTTO',
              lang: 'it',
              iconUrl: '',
            },
            {
              label: 'Notes',
              type: 'attribute',
              component: 'TextCard',
              value: 'NOTE_PRODOTTO_EN',
              lang: 'en',
              iconUrl: '',
            },
            {
              label: 'Tracklist',
              type: 'attribute',
              component: 'ListCard',
              value: 'TRACKLIST',
              iconUrl: '',
            },
            {
              label: 'Media',
              type: 'attribute',
              component: 'IframeCard',
              value: ['SPOTIFY', 'YOUTUBE'],
              iconUrl: '',
            },
          ],
        },
      },
    },
  },

  getters: {
    layouts(state) {
      return state.layouts;
    },
  },
};
